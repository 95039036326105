<template>
  <!--
    The view for DestinationOS/Emma software components
  -->
  <div class="installationDetail_SoftwareComponent">
    <template>
      <SoftwareComponentList
        ref="softwareComponentsList"
        :installation-id="installationId"
        :selected-id="selectedId"
        @setSelectedComponent="setSelectedComponent"
        @reloadAuditLogs="reloadAuditLogs"
        @reloading="setReloading"
      />
      <LoadingPlaceholder v-if="reloading" />
      <div
        v-else-if="selectedComponent"
        class="card mt-4"
      >
        <SoftwareComponentDetail
          :software-component="selectedComponent"
          :installation="installation"
          @reload="reloadSoftwareComponentList"
        />
      </div>
      <DatabaseAuditLogList
        v-if="authenticationHasRole('secondlevel') || authenticationHasRole('admin')"
        ref="databaseAuditLogList"
        :is-software-component="true"
        :installation-id="installationId"
        @reload="reloadSoftwareComponentList"
      />
    </template>
  </div>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';
export default {
  name: "InstallationDetailSoftwareComponent",
  components: {
    SoftwareComponentList: () => import('@/components/SoftwareComponent/SoftwareComponentList.vue'),
    SoftwareComponentDetail: () => import('@/components/SoftwareComponent/SoftwareComponentDetail.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  mixins: [
    authenticationMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    selectedId: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return  {
      selectedComponent: null,
      reloading: false,
      installation: null
    }
  },
  metaInfo () {
    return {
      title: 'Software Components'
    }
  },
  created () {
    this.getInstallation()
  },
   methods: {
      setSelectedComponent (val) {
        this.selectedComponent = val;
      },
      reloadAuditLogs () {
        if(this.authenticationHasRole('secondlevel') || (this.authenticationHasRole('scope_staff') && this.authenticationHasRole('admin'))) {
          this.$refs.databaseAuditLogList.reloadAuditLogs();
        }
      },
      reloadSoftwareComponentList () {
        this.$refs.softwareComponentsList.reloadSoftwareComponents();
      },
      setReloading (val) {
        this.reloading = val
      },
      getInstallation () {
        this.axios.get('/Installation/Get?id=' + this.installationId)
          .then((response) => {
            this.installation = response.data;
          });
      }
    }
}
</script>